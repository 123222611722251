const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: ToggleButton } = require('@material-ui/lab/ToggleButton');

// This is a custom button that is currently only used in one place
exports.FilterButton = Styled(Button)`
    background: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};
    margin: ${({ theme }) => theme.spacing(0, 2)};
    padding: ${({ theme }) => theme.spacing(0.5, 3)};
    font-weight: 600;
    box-shadow: unset;

    &:hover {
        background: ${({ theme }) => theme.palette.primary.light};
        box-shadow: 0 2px 8px -2px rgba(0,96,191,0.6);
    }
`;

exports.LinkButton = Styled.button`
    border: 0;
    background: none;
    text-decoration underline;
    color ${({ theme }) => theme.palette.primary.main};
    outline: none;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
`;

// Primary CTA Button
exports.StyledButton = Styled(Button)`
    color: white;
    padding: ${({ theme }) => theme.spacing(1.5, 4)};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    box-shadow: 0 2px 8px -2px rgba(0,96,191,0.6);
    font-size: 18px;
    font-weight: 600;
    margin: ${({ $spaced, theme }) => ($spaced ? theme.spacing(0, 1) : '')};
    min-width: 180px;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        width: 100%;
    }
`;

exports.StyledTab = Styled(Button)`
    padding: ${({ theme }) => theme.spacing(2, 0)};
    background: ${({ theme }) => theme.palette.white.main};
    color: ${({ active, theme }) => (active ? theme.palette.primary.main : theme.palette.secondary.main)};
    margin: 0;
    width: ${({ $noOfTabs }) => 100 / $noOfTabs}%;
    border: ${({ active, theme }) => (active ? `1px solid ${theme.palette.border.main}` : '1px solid white')};
    border-bottom: ${({ active, theme }) => (active ? '1px solid white' : `1px solid ${theme.palette.border.main}`)};
    outline: 0;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.5s;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        background: white;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        border: 0;
        border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`)};
        // this is weird, but it's to ensure the tab labels don't jump up/down due to thicker border ^ for 'active' tab label
        padding-bottom: ${({ active, theme }) => (active ? `${theme.spacing(2)}px` : `${theme.spacing(2) + 1}px`)};
        font-size: 0.875em; /* 14px */
    }
`;

exports.StyledToggleButton = Styled(ToggleButton)`
    color: ${({ theme }) => theme.palette.secondary.main};
    border: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
    background: white;
    margin-right: 1px; // this means that the border is visible on all sides of both buttons
    text-transform: capitalize;
    padding: ${({ theme }) => theme.spacing(1, 4)};

    &:hover {
        background: ${({ theme }) => theme.palette.primary.light};
    }

    &.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.light};
        border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
        color: ${({ theme }) => theme.palette.primary.main};

        &:hover {
            background: ${({ theme }) => theme.palette.primary.light};
        }
    }


`;
