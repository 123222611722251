const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { PageContainer } = require('./ui/Containers');
const { default: Collapse } = require('@material-ui/core/Collapse');

const Loader = ({ isLoading }) => (

    <Collapse in={isLoading}>
        <StyledLoader isLoading={isLoading}>
            <StyledLoaderContent>
                <div className='circle'>
                    <div className='wave' />
                </div>
            </StyledLoaderContent>
        </StyledLoader>
    </Collapse>
);

Loader.propTypes = {
    isLoading: T.bool
};

module.exports = ({ lazyLoad, ...props }) => {

    if (lazyLoad) {

        return (
            <PageContainer>
                <Loader {...props} />
            </PageContainer>
        );
    }

    return <Loader {...props} />;
};

module.exports.propTypes = {
    lazyLoad: T.bool
};

const StyledLoader = Styled.div`
    min-height: 60vh;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.5s;
`;

const StyledLoaderContent = Styled.div`

    .circle {
        width: 100px;
        height: 100px;
        background: ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        overflow: hidden;
    }

    .wave {
        position: relative;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        animation: fill 3s infinite ease-out alternate;
    }

    .wave:before, .wave:after {
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        top: 0;
        left: 50%;
        transform: translate(-50%,-75%);
        background:#000;
    }

    .wave:before {
        border-radius: 40%;
        background: #f0f3f7;
        animation: rotate 3s linear infinite;
    }

    .wave:after {
        border-radius: 90%;
        background: #f0f3f7;
        animation: rotate 3s linear infinite;
    }

    @keyframes rotate {
        0% { transform:translate(-50%,-75%) rotate(0deg); }
        100% { transform:translate(-50%,-75%) rotate(360deg); }
    }

    @keyframes fill {
        from {
          bottom: -50px;
        }
        to {
          bottom: 80px;
        }
      }
`;
