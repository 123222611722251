const MiddleEnd = require('strange-middle-end');
const { createSelector } = require('reselect');
const RequestClient = require('../utils/requestClient');

module.exports = (m) => {

    const { FETCH_DATA, FETCH_DOWNLOAD_DATA, FETCH_FILTER_DATA } = MiddleEnd.createTypes('app', {
        FETCH_DATA: MiddleEnd.type.async,
        FETCH_DOWNLOAD_DATA: MiddleEnd.type.async,
        FETCH_FILTER_DATA: MiddleEnd.type.async
    });

    return {
        actions: {
            fetchData: MiddleEnd.createAction(FETCH_DATA, {
                index: true,
                async handler(params) {

                    try {
                        const { data } = await RequestClient.get(`/reports`, { params });
                        return data;
                    }
                    catch (err) {
                        throw err;
                    }
                }
            }),
            fetchDownloadData: MiddleEnd.createAction(FETCH_DOWNLOAD_DATA, {
                index: true,
                async handler(query, filters) {

                    const params = { ...query, filters };

                    const { data } = await RequestClient.get('/reports/download', { params, responseType: 'blob' });
                    return data;
                }
            }),
            fetchFilterData: MiddleEnd.createAction(FETCH_FILTER_DATA, {
                index: true,
                async handler() {

                    try {
                        const { data } = await RequestClient.get('/search-fields');
                        return data;
                    }
                    catch (err) {
                        throw err;
                    }
                }
            })
        },
        reducer: MiddleEnd.createEntityReducer({ shouldIndex: (indexName) => indexName.startsWith('app/') }),
        selectors: {
            getData: createSelector(
                (state) => state.app.indexes[FETCH_DATA.BASE] || {},
                (data) => data.result || {}
            ),
            getDataLoading: createSelector(
                (state) => state.app.indexes[FETCH_DATA.BASE],
                (index) => !index || index.inFlight > 0
            ),
            getDownloadLoading: createSelector(
                (state) => state.app.indexes[FETCH_DOWNLOAD_DATA.BASE],
                (index) => index && index.inFlight > 0
            ),
            getFilterLoading: createSelector(
                (state) => state.app.indexes[FETCH_FILTER_DATA.BASE],
                (index) => !index || index.inFlight > 0
            ),
            getFilterData: createSelector(
                (state) => state.app.indexes[FETCH_FILTER_DATA.BASE] || {},
                (data) => data.result || []
            ),
            getLoading: createSelector(
                (state) => state.app.indexes || {},
                (data) => Object.keys(data).some((index) => data[index].inFlight > 0)
            )
        }
    };
};
