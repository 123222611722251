const { default: CreateMuiTheme } = require('@material-ui/core/styles/createMuiTheme');

// The object below overrides specific values and/or extends the default material-ui theme, which can currently be found here:
// https://material-ui.com/customization/default-theme/#default-theme
// Any of the values in that default theme may be used throughout this project, even if they aren't explicitly defined here
// (e.g., `theme.shape.borderRadius` will return 4, even though `shape` isn't currently defined in this file)

const defaultTheme = CreateMuiTheme(); // to make default breakpoints accessible in theme

const systemFontStack = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
const primary = '#0060BF';
const secondary = '#283643';
const white = '#ffffff';
const black = '#000000';
const borderColor = '#dde0e2';
const primaryGrey = '#f6f6f6';
const secondaryGrey = '#747474';
const mutedText = '#757575';
const backgroundColor = '#f0f3f7';
const footerColor = '#e4e9f1';
const footerColorHome = '#f4f4f4';
const primaryLight = '#e5eff8';
const bodyGrey = '#5e5f63';
const lightBoxShadow = '0 0 15px 0 rgba(0,0,0,0.09)';

module.exports = CreateMuiTheme({
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: ['Source Sans Pro', systemFontStack].join(','),
        fontFamilySerif: ['Source Serif Pro', systemFontStack].join(','),
        fontWeightSemiBold: 600, // all other weights are already in theme
        h1: {
            color: secondary,
            fontWeight: 400,
            fontSize: '2.375em', // 38px
            marginBottom: 8,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1.75em' // 28px
            }
        },
        h2: {
            color: secondary,
            fontWeight: 600,
            marginBottom: 16,
            fontSize: '1.75em', // 28px
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1.1875em',
                marginBottom: 0
            }
        },
        h3: { // this is primarily used for the indicator category heading
            color: primary,
            fontWeight: 600,
            fontSize: '1.625em', // 26px
            marginBottom: 16,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1.125em' // 18px
            }
        },
        h4: { // this is primarily used for the individual indicator heading
            color: black,
            fontSize: '1.1875em', // 19px
            fontWeight: 600,
            marginBottom: 16,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1em' // 16px
            }
        },
        h5: { // this is primarily used for the summary view headers
            color: secondary,
            marginBottom: 8,
            fontSize: '1em',
            fontWeight: 600
        },
        caption: {
            color: secondary,
            fontWeight: 600,
            fontSize: '1em',
            textTransform: 'uppercase',
            letterSpacing: '0.6px',
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1em'
            }
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: '1em', // to reset to regular size due to it using h6 tag
            opacity: '0.7',
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: '1em' // 16px
            }
        },
        subtitle2: {
            fontWeight: 500,
            color: mutedText,
            fontSize: '1em' // 16px
        }
    },
    boxShadow: {
        light: lightBoxShadow
    },
    palette: {
        contrastThreshold: 2,
        background: {
            default: backgroundColor
        },
        border: {
            main: borderColor
        },
        footer: {
            main: footerColor,
            home: footerColorHome,
            contrastText: mutedText
        },
        grey: {
            main: primaryGrey,
            dark: secondaryGrey,
            light: mutedText,
            text: bodyGrey
        },
        primary: {
            main: primary,
            contrastText: white,
            light: primaryLight
        },
        secondary: {
            main: secondary,
            contrastText: white
        },
        white: {
            main: white,
            contrastText: black
        }
    },
    shape: {
        borderRadius: '4px'
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    sizing: {
        contentWidth: '1140px' // the max width of the page content a la bootstrap grid
    },
    overrides: {
        MuiAlert: {
            root: {
                padding: '24px 32px',
                width: '75vw',
                [defaultTheme.breakpoints.down('xs')]: {
                    padding: '16px',
                    width: '98vw'
                }
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: 'unset'
            }
        },
        MuiBadge: {
            badge: {
                border: `2px solid ${white}`,
                height: '16px',
                minWidth: '16px'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                margin: '8px 0'
            },
            contained: {
                backgroundColor: primary,
                color: 'white',
                '&:hover': {
                    backgroundColor: secondary
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                button: {
                    fontFamily: ['Source Sans Pro', systemFontStack].join(',')
                },
                a: {
                    textDecoration: 'none'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.875em',
                textTransform: 'none'
            }
        },
        MuiDialog: {
            paper: {
                padding: '16px'
            },
            paperFullScreen: {
                minWidth: 'unset', // to unset the 710px from below for mobile
                padding: '24px 0 0'
            },
            paperWidthSm: {
                maxWidth: '710px', // as per design
                minWidth: '710px' // this way, all modals stay uniform for close button
            }
        },
        MuiDialogActions: {
            root: {
                padding: '16px 24px'
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: white,
                fontSize: '1em'
            }
        },
        MuiInputLabel: {
            root: {
                fontWeight: 600,
                fontSize: '1em'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '1em'
            }
        },
        MuiOutlinedInput: {
            root: {
                // ewww, https://github.com/mui-org/material-ui/issues/13347#issuecomment-435790274
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: primary,
                    borderWidth: '1px'
                }
            },
            notchedOutline: {
                border: `1px solid ${borderColor}`,
                borderColor: 0 // needed to reset some weird material ui style
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: white
                }
            }
        },
        MuiToolbar: {
            dense: {
                minHeight: '40px'
            }
        }
    }
});

// Update these colors when we have finalized from designer
// module.exports.chartColors = [primary, secondary, '#37746A', '#887F33'];
module.exports.chartColors = ['#5d62b5', '#28c3be', '#f3716e', '#ffc534', '#61b58f'];
