const React = require('react');
const { useSelector } = require('react-redux');
const { useMiddleEnd } = require('strange-middle-end');

const Snackbar = require('../components/Snackbar');

module.exports = function AppSnackbarContainer() {

    const [activeMessage, setActiveMessage] = React.useState({ message: '', key: '' });
    const [open, setOpen] = React.useState(false);

    const m = useMiddleEnd();
    const nextMessage = useSelector(m.selectors.snackbar.getCurrentMessage);
    const processMessage = () => m.dispatch.snackbar.processMessage();

    React.useEffect(() => {

        setActiveMessage(nextMessage);
        setOpen(true);

    }, [nextMessage]);

    const handleToggle = () => setOpen((prev) => !prev);

    const handleRemoveMessage = () => processMessage();

    return (
        <Snackbar
            activeMessage={activeMessage}
            handleRemoveMessage={handleRemoveMessage}
            handleToggle={handleToggle}
            open={open}
        />
    );
};
