const React = require('react');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Grid } = require('@material-ui/core/Grid');
const { default: Typography } = require('@material-ui/core/Grid');
const { StyledButton } = require('./ui/Buttons');
const { default: BrokenPipe } = require('../assets/broken-pipe.svg');

const internals = {};

module.exports = function NotFoundPage() {

    const { StyledImg, StyledText } = internals;

    return (
        <div style={{ padding: '16px', minHeight: '90vh', textAlign: 'center', background: 'white' }}>
            <Grid container direction='column' alignItems='center' justify='center'>
                <Grid item>
                    <StyledImg src={BrokenPipe} />
                </Grid>
                <Grid item>
                    <StyledText>Uh-oh, seems we can't find what you're looking for.</StyledText>
                    <NavLink to='/search'>
                        <StyledButton
                            variant='contained'
                            color='primary'
                            size='large'
                        >
                            Explore Data
                        </StyledButton>
                    </NavLink>
                </Grid>
            </Grid>
        </div>
    );
};

internals.StyledImg = Styled.img`
    max-width: 15vw;
    margin: ${({ theme }) => theme.spacing(5, 3, 2)};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        max-width: 40vw;
    }
`;

internals.StyledText = Styled(Typography)`
    font-family: ${({ theme }) => theme.typography.fontFamilySerif};
    font-size: 2em;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    margin: ${({ theme }) => theme.spacing(3, 3)};
`;
