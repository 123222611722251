const FIELDS = {
    COUNTY: 'county',
    SOURCE: 'source',
    UTILITY: 'utility',
    YEAR: 'year',
    YEAR_MAX: 'yearMax',
    YEAR_MIN: 'yearMin'
};

// Fields excluded from building with .map
const EXCLUDED_FIELDS = [FIELDS.YEAR, FIELDS.UTILITY];

// Custom Multiple select fields
const MULTIPLE_SELECT_FIELDS = [FIELDS.COUNTY, FIELDS.UTILITY];

// Toggle fields
const TOGGLE_FIELDS = [FIELDS.SOURCE];

// Not sure if these will be used elsewhere, but storing here jic.
const URLS = {
    about: 'https://www.mainerwa.org/page/AboutUs',
    contact: 'https://www.mainerwa.org/general/?type=CONTACT',
    facebook: 'https://www.facebook.com/MaineRuralWater',
    instagram: 'https://www.instagram.com/maineruralwater'
};

const MESSAGES = {
    breakingError: 'Uh-oh, that was an invalid search. Please try again,',
    downloadError: 'Uh-oh, there was a problem downloading your data. Please try again.',
    genericError: 'Uh-oh, looks like something went wrong. Please try again later.',
    invalidQueryError: 'Uh-oh, looks like there is a problem with your search. Please correct it before continuing.',
    minMaxError: 'Hmm, looks like the "From" is bigger than the "To". Please make another selection.'
};

module.exports = {
    EXCLUDED_FIELDS,
    FIELDS,
    MESSAGES,
    MULTIPLE_SELECT_FIELDS,
    TOGGLE_FIELDS,
    URLS
};
