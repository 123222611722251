const React = require('react');
const T = require('prop-types');
const Header = require('./Header');
const Footer = require('./Footer');
const ErrorFallback = require('./ErrorFallback');
const LoadingFallback = require('./LoadingFallback');
const { default: Styled } = require('styled-components');
const { default: ErrorBoundary } = require('react-error-boundary');
const AppSnackbar = require('../containers/Snackbar');

const internals = {};

module.exports = function Layout({ children, location }) {

    const { Container, AppContainer } = internals;

    return (
        <AppContainer>
            <Header />
            <Container>
                <ErrorBoundary key={location.key} FallbackComponent={ErrorFallback}>
                    <React.Suspense fallback={<LoadingFallback lazyLoad />}>
                        {children}
                    </React.Suspense>
                </ErrorBoundary>
            </Container>
            <AppSnackbar />
            <Footer />
        </AppContainer>
    );
};

module.exports.propTypes = {
    children: T.any,
    location: T.shape({
        key: T.string
    })
};

internals.Container = Styled.div``;

internals.AppContainer = Styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .hide-desktop {
        display: none;
    }

    .hide-mobile {
        display: inline;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        .hide-mobile {
            display: none;
        }

        .hide-desktop {
            display: inline;
        }
    }
`;
