const { default: Styled } = require('styled-components');
const { default: Grid } = require('@material-ui/core/Grid');

exports.CenteredContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ theme }) => theme.spacing(2, 0, 3)};
`;

exports.OverlapContainer = Styled.div`
    background: ${({ theme }) => theme.palette.primary.main};
    min-height: 125px;
`;

exports.PageContainer = Styled.div`
    padding: ${({ theme, condensed }) => (condensed ? theme.spacing(7, 7) : theme.spacing(11, 6))};
    max-width: ${({ theme }) => theme.sizing.contentWidth};
    margin: ${({ theme }) => theme.spacing(-11, 'auto', 10)};
    background: ${({ theme }) => theme.palette.white.main};
    border-radius: 8px;
    min-height: 100vh;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding: ${({ theme }) => theme.spacing(4, 3)};
        margin-bottom: 0;
        border-radius: 0;
    }
`;

exports.FilterRow = Styled(Grid)`
    margin: ${({ theme }) => theme.spacing(1, 0, 4)};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        margin: ${({ theme }) => theme.spacing(1, 0, 3)}; 
    }
`;

exports.FilterSection = Styled.div`
    margin: ${({ theme }) => theme.spacing(2, 0, 4, 0)};
    ${({ noMarginTop }) => noMarginTop && 'margin-top: -2px;'}
    background: ${({ theme }) => theme.palette.white.main};
    padding: ${({ theme }) => theme.spacing(5, 7, 6)};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    border: ${({ theme }) => `1px solid ${theme.palette.border.main}`};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        border: 0;
        padding: ${({ theme }) => theme.spacing(1, 1)};
        margin: ${({ theme }) => theme.spacing(2, 0)};
    }
`;

exports.MaxContainer = Styled.div`
    max-width: ${({ maxWidth }) => maxWidth || ''};
    max-height: ${({ maxHeight }) => maxHeight || ''};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: 100%;
        width: ${({ fullWidthMobile }) => (fullWidthMobile ? '100%' : '')};
    }
`;

exports.ModalSection = Styled.div`
    margin: ${({ theme }) => theme.spacing(1, 0, 3)};
`;

exports.StyledDivider = Styled.hr`
    height: 1px;
    max-width: ${({ theme }) => theme.sizing.contentWidth};
    background: ${({ theme }) => theme.palette.grey.main};
    margin: 0 auto;
    opacity: 0.25;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: ${({ maxWidth }) => (maxWidth || '80%')};
    }
`;
