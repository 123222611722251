const React = require('react');
const { NavLink, useLocation } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: AppBar } = require('@material-ui/core/AppBar');
const { default: Toolbar } = require('@material-ui/core/Toolbar');
const { default: Grid } = require('@material-ui/core/Grid');
const { default: Link } = require('@material-ui/core/Link');
const { default: Collapse } = require('@material-ui/core/Collapse');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: MoreHorizIcon } = require('@material-ui/icons/MoreHoriz');
const { default: MainLogo } = require('../assets/mrwa-logo.png');
const { default: MDWPLogo } = require('../assets/mdwp-logo.png');
const { default: MWUALogo } = require('../assets/mwua-logo.png');
const { default: RCAPLogo } = require('../assets/rcap-logo.png');
const { default: MMBBLogo } = require('../assets/mmbb-logo.png');
const { StyledDivider } = require('./ui/Containers');
const { URLS } = require('../constants');

const internals = {};

const gridProps = {
    container: true,
    alignItems: 'center',
    justify: 'space-between'
};

const MenuLinks = () => {

    const { ExternalLink, InternalLink } = internals;

    return (
        <>
            <InternalLink to='/'>Home</InternalLink>
            <span className='hide-mobile'>|</span>
            <InternalLink to='/#about'>About</InternalLink>
            <span className='hide-mobile'>|</span>
            <ExternalLink href={URLS.contact} target='_blank' rel='noopener noreferrer'>Contact</ExternalLink>
        </>
    );
};

module.exports = function Header() {

    const location = useLocation();
    const isHomepage = location && location.pathname === '/';

    const [showMobileMenu, setShowMobileMenu] = React.useState(false);

    const { MobileSubMenu, StyledLink, SiteTitle, StyledGrid, StyledHeader, StyledImage, StyledToolbar } = internals;

    const handleToggleMobileMenu = () => setShowMobileMenu((prev) => !prev);

    return (
        <StyledHeader $isHome={isHomepage}>
            <AppBar position='static'>
                <StyledToolbar variant='dense' $isHome={isHomepage}>
                    <Grid {...gridProps}>
                        <StyledLink color='inherit' to='/'><SiteTitle variant='body2'>Maine Rural Water Association</SiteTitle></StyledLink>
                        {/* Desktop Header Menu */}
                        <div className='hide-mobile'>
                            <MenuLinks />
                        </div>

                        {/* Mobile Header Menu Trigger */}
                        <div className='hide-desktop'>
                            <IconButton color='inherit' onClick={handleToggleMobileMenu}>
                                <MoreHorizIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </StyledToolbar>
                {/* Mobile Header Collapsible Menu */}
                <div className='hide-desktop'>
                    <Collapse in={showMobileMenu}>
                        <MobileSubMenu $isHome={isHomepage} container direction='column' justify='space-between'>
                            <MenuLinks />
                        </MobileSubMenu>
                    </Collapse>
                </div>
            </AppBar>
            <StyledGrid {...gridProps} justify='space-evenly'>
                <StyledLink to='/'>
                    <StyledImage $isHome={isHomepage} src={MainLogo} height='40px' alt='mrwa-logo' $invert />
                </StyledLink>
                <StyledImage $isHome={isHomepage} src={MDWPLogo} small height='40px' alt='mdwp-logo' />
                <StyledImage $isHome={isHomepage} src={MWUALogo} small height='32px' alt='mwua-logo' $invert />
                <StyledImage $isHome={isHomepage} src={RCAPLogo} small height='40px' alt='rcap-logo' />
                <StyledImage $isHome={isHomepage} src={MMBBLogo} small height='40px' alt='mmbb-logo' $invert />
            </StyledGrid>
            {!isHomepage && <StyledDivider />}
        </StyledHeader>
    );
};

internals.ExternalLink = Styled(Link).attrs({ component: Link, color: 'inherit' })`
    font-weight: 600;
    margin: ${({ theme }) => theme.spacing(1, 1)};
    color: inherit;
`;

internals.InternalLink = Styled(NavLink)`
    font-weight: 600;
    margin: ${({ theme }) => theme.spacing(1, 1)};
    color: inherit;
`;

internals.MobileSubMenu = Styled(Grid)`
    background: white;
    padding: ${({ theme }) => theme.spacing(3, 5)};
    color: ${({ $isHome, theme }) => ($isHome ? theme.palette.grey.text : theme.palette.primary.main)};
`;

internals.StyledLink = Styled(Link).attrs({ component: NavLink })``;

internals.SiteTitle = Styled(Typography)`
    font-weight: 600;
`;

internals.StyledGrid = Styled(Grid)`
    padding: ${({ theme }) => theme.spacing(3, '10%', 2)};
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

internals.StyledHeader = Styled.div`
    background: ${({ $isHome, theme }) => ($isHome ? theme.palette.white.main : theme.palette.primary.main)};
`;

internals.StyledToolbar = Styled(Toolbar)`
    height: 40px;
    padding: ${({ theme }) => theme.spacing(0, '10%')};
    background: white;
    color: ${({ $isHome, theme }) => ($isHome ? theme.palette.grey.text : theme.palette.primary.main)};
    ${({ $isHome, theme }) => $isHome && `border-bottom: 1px solid ${theme.palette.grey.main};`}

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        .MuiIconButton-root {
            padding: 0;
        }
    }
`;

internals.StyledImage = Styled.img`
    height: ${({ height }) => height || '40px' };
    margin: ${({ theme }) => theme.spacing(0, 1)};
    ${({ $isHome, $invert }) => $isHome && `filter: invert(${$invert ? '1' : '0'}) grayscale(1) opacity(${$invert ? '0.60' : '0.80'});`}

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        height: ${({ small }) => (small ? '20px' : '32px')};
        margin: ${({ theme }) => theme.spacing(1, 0.5)};
    }
`;
