const MiddleEnd = require('strange-middle-end');
const { createSelector } = require('reselect');

module.exports = (m) => {

    const { ADD_MESSAGE, PROCESS_MESSAGE } = MiddleEnd.createTypes('snackbar', {
        ADD_MESSAGE: MiddleEnd.type.simple,
        PROCESS_MESSAGE: MiddleEnd.type.simple
    });

    return {
        actions: {
            addMessage: MiddleEnd.createAction(ADD_MESSAGE, {
                transform: (messageInfo) => {

                    return {
                        message: messageInfo.message || messageInfo || '',
                        key: messageInfo.key || new Date().getTime(),
                        state: messageInfo.state || 'error'
                    };
                }
            }),
            processMessage: MiddleEnd.createAction(PROCESS_MESSAGE)
        },
        reducer: MiddleEnd.createReducer({ queue: [] }, {
            [ADD_MESSAGE]: (state, { payload }) => ({ ...state, queue: [...state.queue, payload] }),
            [PROCESS_MESSAGE]: (state) => ({ ...state, queue: state.queue.slice(0, -1) })
        }),
        selectors: {
            getCurrentMessage: createSelector(
                (state) => state.snackbar,
                (snackbar) => snackbar.queue[0] || {}
            )
        }
    };
};
