const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { NavLink } = require('react-router-dom');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Grid } = require('@material-ui/core/Grid');
const { StyledButton } = require('../../../components/ui/Buttons');
const { MaxContainer } = require('../../../components/ui/Containers');
const { default: HeroChart } = require('../../../assets/hero-chart.svg');
const { default: Pipes } = require('../../../assets/pipes.png');
const { StepOne, StepTwo, StepThree } = require('./Illustrations');

const internals = {};

const gridProps = {
    container: true,
    wrap: 'nowrap',
    alignItems: 'center',
    justify: 'center'
};

module.exports = function Homepage(props) {

    const howItWorksRef = React.useRef(null);
    const aboutRef = React.useRef(null);

    const {
        ButtonLink,
        Header,
        HeroImage,
        HomepageContainer,
        HomepageSection,
        PrimaryCTA,
        StepImage,
        StyledDivider,
        StyledLine,
        StyledText,
        SubHeader
    } = internals;

    React.useEffect(() => {

        if (props.location.hash === '#about') {
            handleScroll(aboutRef);
        }

    }, [props.location.hash]);

    const handleScroll = (ref) => {

        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    return (
        <div style={{ background: 'white' }}>
            <HomepageContainer>
                {/* Header Section */}
                <HomepageSection>
                    <Grid container justify='space-between'>
                        <Grid item xs={12} sm={6}>
                            <Header>
                                Financial and Operational Measures for Maine Water Utilities
                            </Header>
                            <MaxContainer maxWidth='450px' style={{ margin: '-16px 0 40px' }}>
                                <StyledText className='sub-title'>
                                    Data visualization for financial and operational parameters for Maine water utilities
                                </StyledText>
                            </MaxContainer>
                            <Grid container wrap='nowrap' alignItems='center'>
                                <NavLink to='/search'>
                                    <PrimaryCTA
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                    >
                                        Explore Data
                                    </PrimaryCTA>
                                </NavLink>
                                <ButtonLink onClick={() => handleScroll(howItWorksRef)}>How does it work?</ButtonLink>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HeroImage src={HeroChart} alt='hero-chart' />
                        </Grid>
                    </Grid>
                </HomepageSection>

                {/* How it works section */}
                <HomepageSection noPadding ref={howItWorksRef}>
                    <Grid container direction='column' alignItems='center'>
                        <StyledText className='pre-header'>OUR APP</StyledText>
                        <SubHeader>How does it work?</SubHeader>
                        <StyledText className='step-description'>
                            A step-by-step example, when you're ready to begin, click 'Get Started'.
                        </StyledText>

                        {/* Lines between step headers */}
                        <StyledLine className='container' {...gridProps}>
                            <StyledLine {...gridProps}>
                                <div className='circle' />
                                <div className='line' />
                                <div className='circle' />
                            </StyledLine>
                            <div style={{ margin: '0 56px' }} />
                            <StyledLine {...gridProps}>
                                <div className='circle' />
                                <div className='line' />
                                <div className='circle' />
                            </StyledLine>
                        </StyledLine>

                        <Grid container alignItems='center' justify='space-evenly'>
                            <Grid item>
                                {/* Step 1 */}
                                <Grid container direction='column' alignItems='center'>
                                    <SubHeader className='step'>Step N<sup>o</sup>1</SubHeader>
                                    <StyledText className='step-description'>Select Utilities or Parameters</StyledText>
                                    <StepImage>
                                        <StepOne />
                                    </StepImage>
                                </Grid>
                            </Grid>


                            {/* Step 2 */}
                            <Grid item>
                                <Grid container direction='column' alignItems='center'>
                                    <SubHeader className='step'>Step N<sup>o</sup>2</SubHeader>
                                    <StyledText className='step-description'>Select desired years</StyledText>
                                    <StepImage>
                                        <StepTwo />
                                    </StepImage>
                                </Grid>
                            </Grid>

                            {/* Step 3 */}
                            <Grid item>
                                <Grid container direction='column' alignItems='center'>
                                    <SubHeader className='step'>Step N<sup>o</sup>3</SubHeader>
                                    <StyledText className='step-description'>Visualize Generated Data, or download as CSV</StyledText>
                                    <StepImage>
                                        <StepThree />
                                    </StepImage>
                                </Grid>
                            </Grid>
                        </Grid>
                        <NavLink to='/search'>
                            <PrimaryCTA
                                variant='contained'
                                color='primary'
                                size='large'
                            >
                                Get Started
                            </PrimaryCTA>
                        </NavLink>
                    </Grid>
                </HomepageSection>

                <StyledDivider />

                {/* Further details section */}
                <HomepageSection $last ref={aboutRef}>
                    <Grid container justify='space-between'>
                        <Grid item xs={12} sm={6}>
                            <HeroImage src={Pipes} alt='pipes' $condensed />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledText className='pre-header'>ABOUT APP</StyledText>
                            <SubHeader>
                                Maine's Water Utility Financial Benchmarking Project
                            </SubHeader>
                            <StyledText className='step-description'>
                                This app is intended to be used to assist with water system management by water system managers,
                                policy makers, and regulators. This provides them with a tool to communicate and understand current
                                financial and asset conditions of individual utilities and the water industry in Maine.
                                The tool provides a rational electronic database with standardized key financial and operational
                                metrics to assess financial health and operational parameters for water utilities.
                                The current data set includes data for 41 water systems with the goal to include data from all
                                150 PUC regulated public water systems. Interest with Measures and Benchmarks has been discussed
                                by many members of Maine water utilities over several years.
                            </StyledText><br />

                            <StyledText className='step-description'>
                                The data source is primarily from individual utilities filing of an Annual Report to the Maine Public
                                Utility Commission. Addition data comes from the Maine Drinking Water Program as well as
                                information provided directly by utility managers.
                            </StyledText><br />

                            <StyledText className='step-description'>
                                This effort is supported by representatives from Maine Rural Water Association, Maine Water Utilities
                                Association, RCAP Solutions, Maine Drinking Water Program, and water system managers and
                                operators from many systems. The Median Household Income (MHI) information is from the
                                U.S. Census American Community Survey.
                            </StyledText><br />

                            <StyledText className='step-description'>
                                Development of the Financial & Operational Measures has been made possible with funding
                                through the State of Maine Drinking Water Program State Revolving Fund
                            </StyledText>
                        </Grid>
                    </Grid>
                </HomepageSection>
            </HomepageContainer>
        </div>
    );
};

module.exports.propTypes = {
    location: T.shape({
        hash: T.string
    })
};

// This component contains a lot of CSS specific only to the landing page (due to design)
internals.ButtonLink = Styled.button`
    border: 0;
    outline: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
    margin: ${({ theme }) => theme.spacing(0, 3)};
    background: ${({ theme }) => theme.palette.white.main};
    font-size: 1em;
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

internals.HeroImage = Styled.img`
    max-width: 45vw;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: 90vw;
        margin: ${({ $condensed, theme }) => ($condensed ? theme.spacing(1, 0) : theme.spacing(4, 0))};
    }
`;

internals.HomepageContainer = Styled.div`
    max-width: 1200px; // as per design
    margin: ${({ theme }) => theme.spacing(0, 'auto')};
    padding: ${({ theme }) => theme.spacing(4, 0)};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding: ${({ theme }) => theme.spacing(0, 2)};
    }
`;

internals.HomepageSection = Styled.div`
    margin: ${({ theme }) => theme.spacing(1, 0, 10)};
    padding: ${({ theme }) => theme.spacing(1, 2)};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        margin: ${({ theme, $last }) => theme.spacing(0, 0, $last ? 0 : 4)};
        ${({ noPadding, theme }) => noPadding && `padding: ${theme.spacing(1, 0)};`}
    }
`;

internals.Header = Styled.h1`
    font-family: ${({ theme }) => theme.typography.fontFamilySerif};
    font-size: 3.43em;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    letter-spacing: -1.5px;
    line-height: 1.15em;
    color: #242424; 

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        font-size: 1.93em;
        letter-spacing: -0.89px;
        line-height: 1.2em;
    }
`;

internals.SubHeader = Styled.h2`
    font-family: ${({ theme }) => theme.typography.fontFamilySerif};
    font-size: 2em;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: 2.5em;
    letter-spacing: -1.15px;
    line-height: 1.15em;
    margin: ${({ theme }) => theme.spacing(2, 0, 2)};
    color: #3c3c3c;

    &.step {
        font-size: 1.375em;
        margin: ${({ theme }) => theme.spacing(6, 0, 2)};

        @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            margin: ${({ theme }) => theme.spacing(3, 0, 1)};
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        font-size: 1.875em;
    }
`;

internals.PrimaryCTA = Styled(StyledButton)`
    border-radius: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: 1.06em; // 17px
    letter-spacing: 0.85px;
    text-transform: uppercase;
    min-width: 200px;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        min-width: unset;
        padding: ${({ theme }) => theme.spacing(2, 2)};
        white-space: nowrap;
    }
`;

internals.StyledDivider = Styled.hr`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    border-top: ${({ theme }) => `1px solid ${theme.palette.grey.main}`};
    margin: ${({ theme }) => theme.spacing(10, '-50vw', 10, '-50vw')};
`;

internals.StepImage = Styled.div`
    box-shadow: ${({ theme }) => theme.boxShadow.light};
    border-radius: 7px;
    padding: ${({ theme }) => theme.spacing(3, 3)};
    margin: ${({ theme }) => theme.spacing(3, 2)};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        margin: ${({ theme }) => theme.spacing(3, 0)};
    }
`;

internals.StyledText = Styled(Typography)`

    &.pre-header {
        font-size: 1em;
        font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
        color: ${({ theme }) => theme.palette.grey.light};
        letter-spacing: 0.84px;
    }

    &.sub-title {
        font-size: 1.1875em;
        color: ${({ theme }) => theme.palette.grey.text};
        line-height: 26px;
    }

    &.step-description {
        font-size: 1.1em;
        color: ${({ theme }) => theme.palette.grey.text};
        margin: ${({ theme }) => theme.spacing(0, 0, 3)};


        @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            margin: ${({ theme }) => theme.spacing(1, 0)};
        }
    }
`;

internals.StyledLine = Styled(Grid)`

    &.container {
        display: inherit;
        position: relative;
        top: 72px;
        max-width: 57%;
    }

    .line {
        background: #e5e5e5;
        height: 1px;
        width: 100%;
    }

    .circle {
        background: #e5e5e5;
        border-radius: 100%;
        width: 7px;
        height: 7px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
        display: none;
    }
`;
