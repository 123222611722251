const React = require('react');
const Layout = require('../components/Layout');
const NotFoundPage = require('../components/NotFoundPage');
const NotFoundHelpers = require('./helpers/not-found');
const Homepage = require('./homepage/components/Homepage');

const FilterPage = React.lazy(() => import('./filter/containers/FilterPage'));
const ReportPage = React.lazy(() => import('./report/containers/ReportPage'));

module.exports = [
    {
        path: '/',
        component: NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage),
        childRoutes: [
            {
                path: '/',
                component: Homepage,
                exact: true
            },
            {
                path: '/search',
                component: FilterPage,
                exact: true
            },
            {
                path: '/report',
                component: ReportPage,
                exact: true
            },
            NotFoundHelpers.CatchAllRoute
        ]
    }
];
