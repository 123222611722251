const React = require('react');
const T = require('prop-types');
const { default: Snackbar } = require('@material-ui/core/Snackbar');
const { default: MuiAlert } = require('@material-ui/lab/Alert');

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

module.exports = function AppSnackbar({ activeMessage, handleRemoveMessage, handleToggle, open }) {

    const handleClose = (_, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        handleToggle();
    };

    if (!activeMessage.message) {
        return null;
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            autoHideDuration={4000}
            key={activeMessage.key}
            onClose={handleClose}
            onExited={handleRemoveMessage}
            open={open}
        >
            <Alert onClose={handleClose} severity={activeMessage.state}>{activeMessage.message}</Alert>
        </Snackbar>
    );
};

module.exports.propTypes = {
    activeMessage: T.object,
    handleRemoveMessage: T.func,
    handleToggle: T.func,
    open: T.bool
};
