const React = require('react');
const { useLocation } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: FacebookIcon } = require('@material-ui/icons/Facebook');
const { default: Grid } = require('@material-ui/core/Grid');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: InstagramIcon } = require('@material-ui/icons/Instagram');
const { default: Typography } = require('@material-ui/core/Typography');
const { MaxContainer } = require('./ui/Containers');
const { URLS } = require('../constants');
const { default: MainLogo } = require('../assets/mrwa-logo.png');

const internals = {};

module.exports = function Footer() {

    const location = useLocation();
    const isHomepage = location && location.pathname === '/';

    const { StyledFooter, StyledLogo } = internals;

    return (
        <StyledFooter
            $isHome={isHomepage}
            container
            alignItems='center'
            justify='space-evenly'
            direction='column'
        >
            <StyledLogo src={MainLogo} />
            <MaxContainer>
                <Typography variant='subtitle1' align='center'>Maine Rural Water Association</Typography>
                <Typography variant='subtitle1' align='center'>PO Box 263 | 254 Alexander Reed Rd | Richmond</Typography>
                <Typography variant='subtitle1' align='center'>ME 04357 | Tel: 207-737-4092 | Fax: 207-737-7150</Typography>
            </MaxContainer>
            <div>
                <IconButton
                    href={URLS.facebook}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FacebookIcon />
                </IconButton>
                <IconButton
                    href={URLS.instagram}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <InstagramIcon />
                </IconButton>
            </div>
        </StyledFooter>
    );
};

internals.StyledFooter = Styled(Grid)`
    min-height: 390px; // fixed from design
    background: ${({ $isHome, theme }) => ($isHome ? theme.palette.footer.home : theme.palette.footer.main)};
    color: ${({ theme }) => theme.palette.footer.contrastText};

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding: ${({ theme }) => theme.spacing(1, 3)};
    }
`;

internals.StyledLogo = Styled.img`
    height: 50px;
    filter: brightness(0) saturate(100%) invert(65%) sepia(4%) saturate(674%) hue-rotate(176deg) brightness(90%) contrast(92%); // https://codepen.io/sosuke/pen/Pjoqqp
    margin: ${({ theme }) => theme.spacing(4, 0, 1)};
`;
